<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="slide-fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <vue-ins-progress-bar></vue-ins-progress-bar>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import "vue-select/dist/vue-select.css";

export default {
  name: "App",
  mounted() {
    this.$insProgress.finish();
  },
  created() {
    this.$insProgress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start();
      next();
    });

    this.$router.afterEach((to, from) => {
      this.$insProgress.finish();
    });
  },
};
</script>
<style>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #5cb85c #e8e8e8;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #e8e8e8;
}

*::-webkit-scrollbar-thumb {
  background-color: #5cb85c;
  border-radius: 8px;
  border: 2px solid #e8e8e8;
}
</style>
