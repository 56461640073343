import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth.js";
import Requests from "./modules/requests.js";
import Projects from "./modules/projects.js";
import Bank from "./modules/bank.js";
import Commitments from "./modules/commitments.js";
import Fundings from "./modules/fundings.js";
import Forums from "./modules/forums.js";
import Bucket from "./modules/bucket.js";

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    Auth,
    Requests,
    Projects,
    Bank,
    Bucket,
    Commitments,
    Fundings,
    Forums
  },
});
