import { icp_request } from "../../config/axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    subBuckets: [],
  },
  getters: {
    get_sub_bucket(state) {
      return state.subBuckets;
    },
  },
  mutations: {
    SET_SUB_BUCKET: (state, value) => (state.subBuckets = value),
  },
  actions: {
    async geSubBucketByBucketId({ commit }, idBucket) {
      await icp_request
        .get("public/buckets/get/sub/" + idBucket, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response) {
            commit("SET_SUB_BUCKET", response.data);
          }
        });
    },
    async createSubBucket({ dispatch }, sub) {
      await icp_request
        .post("public/buckets/add/sub", sub, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.data) {
            dispatch("Requests/getRequestById", sub.request, { root: true });
            Swal.fire({
              icon: "success",
              title: "Ajout Reussie !",
              text: "Le sous dossier a été rajouté à la liste en dernière position",
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: error.message,
          });
        });
    },
  },
};
