import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store/index";
import uploader from "vue-simple-uploader";

Vue.use(uploader);

Vue.use(VueRouter);

// Fonction pour sécurisée une route si l 'utilisateur n'a pas de session active

function guardAdmin(to, from, next) {
  if (localStorage.getItem("icp_pass") && store.getters["Auth/isActive"]) {
    next();
  } else {
    next({ name: "login" });
  }
}

function guardClient(to, from, next) {
  if (localStorage.getItem("icp_pass") && store.getters["Auth/isActive"]) {
    next({ name: "dashboard" });
  } else {
    next();
  }
}


const routes = [
  {
    path: "/",
    name: "login",
    beforeEnter: guardClient,
    component: () =>
      import(
        /* webpackChunkName: "authentification" */ "../views/authentification/login.vue"
      ),
    meta: {
      title: "ACCUEIL | SPF",
      metaTags: [
        {
          name: "description",
          content: "...",
        },
      ],
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "authentification" */ "../views/authentification/register.vue"
      ),
  },
  {
    path: "/password/reset/:token",
    props:true,
    name: "password_reset",
    beforeEnter: guardClient,
    component: () =>
      import(
        /* webpackChunkName: "reset" */ "../views/password/reset.vue"
      ),
    meta: {
      title: "REINITIALISATION DE MOT DE PASSE | SPF",
      metaTags: [
        {
          name: "description",
          content: "...",
        },
      ],
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    beforeEnter: guardAdmin,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/dashboard/index.vue"
      ),
    redirect: { name: "statistics" },
    children: [
      {
        name: "statistics",
        path: "",
        beforeEnter: guardAdmin,
        meta: {
          title: "TABLEAU DE BOARD | SPF",
          metaTags: [
            {
              name: "description",
              content: "...",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/statistics.vue"
          ),
      },
      {
        path: "forums",
        name: "forums",
        meta: {
          title: "FORUMS | SPF",
          metaTags: [
            {
              name: "description",
              content: "...",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "forums" */ "../views/dashboard/forums/index.vue"
          ),
        beforeEnter: guardAdmin,
        redirect: { name: "welcomeForum" },
        children: [
          {
            name: "welcomeForum",
            meta: {
              title: "FORUMS | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "request" */ "../views/dashboard/forums/welcome.vue"
              ),
          },
          {
            meta: {
              title: "FORUMS | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: ":forumId",
            name: "showForum",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "request" */ "../views/dashboard/forums/show.vue"
              ),
          },
        ],
      },
      {
        path: "requests",
        beforeEnter: guardAdmin,
        name: "requests",
        meta: {
          title: "DEMANDES | SPF",
          metaTags: [
            {
              name: "description",
              content: "...",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "request" */ "../views/dashboard/requests/index.vue"
          ),
        redirect: { name: "welcomeRequest" },
        children: [
          {
            name: "welcomeRequest",
            meta: {
              title: "DEMANDES | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "request" */ "../views/dashboard/requests/welcome.vue"
              ),
          },
          {
            path: ":request",
            meta: {
              title: "DEMANDES | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            name: "showRequest",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "request" */ "../views/dashboard/requests/show.vue"
              ),
          },
        ],
      },
      {
        path: "recovery",
        beforeEnter: guardAdmin,
        name: "recovery",
        meta: {
          title: "REPRISE | SPF",
          metaTags: [
            {
              name: "description",
              content: "...",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "recovery" */ "../views/recovery/index.vue"
          ),
        redirect: { name: "welcomeRecovery" },
        children: [
          {
            name: "welcomeRecovery",
            meta: {
              title: "REPRISE | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "recovery" */ "../views/recovery/welcome.vue"
              ),
          },
          {
            name: "addCompany",
            path: "addCompany",
            component: () =>
              import(
                /* webpackChunkName: "recovery" */ "../views/recovery/createCompany.vue"
              ),
          },
          {
            name: "addUser",
            path: "addUser",
            component: () =>
              import(
                /* webpackChunkName: "recovery" */ "../views/recovery/createUser.vue"
              ),
          },
          {
            name: "addRequest",
            path: "addRequest",
            component: () =>
              import(
                /* webpackChunkName: "recovery" */ "../views/recovery/createRequest.vue"
              ),
          },
        ],
      },
      {
        path: "fundings",
        beforeEnter: guardAdmin,
        name: "fundings",
        meta: {
          title: "FINANCEMENT | SPF",
          metaTags: [
            {
              name: "description",
              content: "...",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/index.vue"
          ),
        redirect: { name: "welcomeFunding" },
        children: [
          {
            name: "welcomeFunding",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/welcome.vue"
              ),
          },
          {
            path: ":fundingId",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            name: "showFunding",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/show.vue"
              ),
          },
          {
            name: "followUpActions",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            props: true,
            path: ":fundingId/followUpActions",
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/followUpActions.vue"
              ),
          },
          {
            name: "refund2",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: ":fundingId/refund2",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/refund2.vue"
              ),
          },
          {
            name: "financialInfos",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: ":fundingId/informations",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/financialInfos.vue"
              ),
          },
          {
            name: "turnover",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: ":fundingId/turnover",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/turnover.vue"
              ),
          },
          {
            name: "executionPlan",
            meta: {
              title: "FINANCEMENT | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: ":fundingId/executionPlan",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "fundings" */ "../views/dashboard/fundings/executionPlan.vue"
              ),
          },
        ],
      },
      {
        path: "commitments",
        meta: {
          title: "NOTIFICATIONS | SPF",
          metaTags: [
            {
              name: "description",
              content: "...",
            },
          ],
        },
        beforeEnter: guardAdmin,
        name: "commitments",
        component: () =>
          import(
            /* webpackChunkName: "notification" */ "../views/dashboard/commitments/index.vue"
          ),
        redirect: { name: "commitments" },
        children: [
          {
            name: "notifications",
            meta: {
              title: "NOTIFICATIONS | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "notification" */ "../views/dashboard/commitments/notifications.vue"
              ),
          },
          {
            path: ":commitmentId",
            meta: {
              title: "NOTIFICATIONS | SPF",
              metaTags: [
                {
                  name: "description",
                  content: "...",
                },
              ],
            },
            name: "showCommitment",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "notification" */ "../views/dashboard/commitments/show.vue"
              ),
          },
        ],
      },
      {
        path: "/createBank",
        name: "createBank",
        component: () =>
          import(
            /* webpackChunkName: "authentification" */ "../views/dashboard/bank/create.vue"
          ),
      },
    ],
  },
  {
    // matches everything else
    meta: {
      title: "INTROUVABLE | SPF",
      metaTags: [
        {
          name: "description",
          content: "...",
        },
      ],
    },
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/error/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
