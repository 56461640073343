import { icp_request } from "../../config/axios";

export default {
  namespaced: true,
  state: {
    forumsList: [],
    forum: [],
    messages: [],
    notifications: [],
  },
  getters: {
    get_forums_list(state) {
      return state.forumsList;
    },
    get_forum(state) {
      return state.forum;
    },
    get_messages(state) {
      return state.messages;
    },
    get_notification(state) {
      return state.notifications;
    },
  },
  mutations: {
    SET_FORUMS_LIST: (state, value) => (state.forumsList = value),
    SET_FORUM: (state, value) => (state.forum = value),
    SET_MESSAGES: (state, value) => (state.messages = value),
    SET_NOTIFICATION_LIST: (state, value) => (state.notifications = value),
  },
  actions: {
    async getAllForums({ commit }) {
      await icp_request.get("public/forums/all").then((response) => {
        if (response.data.forums) {
          commit("SET_FORUMS_LIST", response.data.forums);
        }
      });
    },
    async getAllNotification({ commit }) {
      await icp_request
        .get("public/messages/allNotification")
        .then((response) => {
          if (response.data.notifications) {
            commit("SET_NOTIFICATION_LIST", response.data.notifications);
          }
        });
    },
    async getForumById({ commit }, forumId) {
      await icp_request
        .get("public/forums/get/" + forumId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_FORUM", response.data.forum);
            commit("SET_MESSAGES", response.data.messages);
          }
        });
    },
    async readForumById(_, forumId) {
      await icp_request.get("public/forums/read/" + forumId, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
    },
    async addMessage(_, message) {
      await icp_request
        .post("public/messages/add", message, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
          }
        });
    },
  },
};
