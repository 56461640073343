import { icp_request } from "../../config/axios";
import router from "../../router";
import Swal from "sweetalert2";
import axios from "axios";
const url = process.env.VUE_APP_SPF_FILE_DOWNLOAD_URL;
// import.meta.env.VITE_SPF_FILE_DOWNLOAD_URL;
export default {
  namespaced: true,
  state: {
    allRequests: [],
    selectedRequest: [],
    selectedsBuckets: [],
    selectedCommitment: [],
    selectedTimeline: [],
    documentsList: [],
    usersList: [],
    companiesList: [],
    listSelection: [],
    loadingDocs: false,
    loadingIframe: false,
  },
  getters: {
    get_requests_list(state) {
      return state.allRequests;
    },
    get_selected_request(state) {
      return state.selectedRequest;
    },
    get_request_buckets(state) {
      return state.selectedsBuckets;
    },
    get_request_commitment(state) {
      return state.selectedCommitment;
    },
    get_request_timeline(state) {
      return state.selectedTimeline;
    },
    get_documents_list(state) {
      return state.documentsList;
    },
    get_list_selection(state) {
      return state.listSelection;
    },
    get_loading_docs(state) {
      return state.loadingDocs;
    },
    get_users_list(state) {
      return state.usersList;
    },
    get_companies_list(state) {
      return state.companiesList;
    },
  },
  mutations: {
    SET_ALL_REQUEST: (state, value) => (state.allRequests = value),
    SET_SELECTED_REQUEST: (state, value) => (state.selectedRequest = value),
    SET_S_REQUEST_BUCKETS: (state, value) => (state.selectedsBuckets = value),
    SET_S_REQUEST_COMMITMENTS: (state, value) =>
      (state.selectedCommitment = value),
    SET_S_REQUEST_TIMELINE: (state, value) => (state.selectedTimeline = value),
    SET_DOCUMENTS_LIST: (state, value) => (state.documentsList = value),
    SET_LIST_SELECTION: (state, value) => (state.listSelection = value),
    SET_LOADING_DOCS: (state, value) => (state.loadingDocs = value),
    SET_USERS_LIST: (state, value) => (state.usersList = value),
    SET_COMPANIES_LIST: (state, value) => (state.companiesList = value),
  },
  actions: {
    async getAllRequests({ commit }) {
      await icp_request.get("public/requests/all").then((response) => {
        // console.log(response.data)
        if (response.data) {
          commit("SET_ALL_REQUEST", response.data.requests);
        }
      });
    },

    async sendForAuthorisation(_, data) {
      await icp_request
        .post("public/requests/transfer/responsable", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log("response : "+response)
          // console.log("response data "+response.data)
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Transmission réussie !",
              text: "La demande de financement a été envoyée au responsable avec succès",
            });
            router.push({ name: "welcomeRequest" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Transmission echouée !",
              text: "Veuillez joindre au moins un document",
            });
          }
        });
    },
    async returnToAnalystForCorrect(_, document) {
      await icp_request
        .post("public/requests/transfer/rectification", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Retour réussi !",
              text: "La demande de financement a été retournée à l'analyste avec succès",
            });
            router.push({ name: "welcomeRequest" });
          }
        });
    },
    async getAllUsers({ commit }) {
      await icp_request.get("private/users/all").then((response) => {
        // console.log(response.data)
        if (response.data) {
          // console.log(response.data);
          commit("SET_USERS_LIST", response.data);
        }
      });
    },
    async getAllCompanies({ commit }) {
      await icp_request.get("public/companies/all").then((response) => {
        // console.log(response.data)
        if (response.data) {
          // console.log(response.data);
          commit("SET_COMPANIES_LIST", response.data);
        }
      });
    },
    async assignRequest(_, requests) {
      await icp_request
        .post("private/users/assign", requests, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Imputation réussie !",
              text: "L'imputation des demandes a été réalisée avec succès",
            });
          }
        });
    },
    async getListSelection({ commit }, requestId) {
      await icp_request
        .get("public/requests/getListSelection/" + requestId)
        .then((response) => {
          if (response.data) {
            commit("SET_LIST_SELECTION", response.data.documents);
          }
        });
    },
    async createBucketOnRequest({ dispatch }, bucket) {
      await icp_request
        .post("public/buckets/add", bucket, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.data) {
            dispatch("Requests/getRequestById", bucket.request,{ root: true });
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "Le dossier a été rajouté avec succès",
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: error.message,
          });
        });
    },
    async deleteBucketOnRequest(_, bucketId) {
      await icp_request
        .get("public/buckets/remove", {
          params: { bucketId: bucketId },
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussie !",
              text: "Le dossier a bien été supprimé",
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: "Pour supprimer le dossier, veuillez d'abord supprimer tous les documents contenus dans le dossier",
          });
        });
    },
    async joinFile({ dispatch }, document) {
      await icp_request
        .post("public/documents/add", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log("==>>>> "+document.get("bucket"));

          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "Le document a été rajouté avec succès",
            });
            dispatch("Requests/getBucketDocs", document.get("bucket"),{ root: true });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: error.message,
          });
        });
    },
    async removeFile(_, document) {
      await icp_request
        .post("public/documents/removeFile", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log("==>>>> "+document.get("bucket"));

          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussie !",
              text: "Le fichier a été supprimé avec succès",
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: error.message,
          });
        });
    },
    async deleteDocument(_, document) {
      await icp_request
        .get("public/documents/remove", {
          params: { documentId: document.get("documentId") },
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Supression réussie !",
              text: "Le document a été supprimé avec succès",
            });
            // console.log("Le document ==> : "+document.get("documentId"));
          }
        });
    },
    async updateDocument(_, document) {
      await icp_request
        .post("public/documents/update", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Modification réussie !",
              text: "Le document a été joint avec succès",
            });
            // console.log("Le document ==> : "+document.get("documentId"));
          }
        });
    },
    async setRequestedCredit(_, request) {
      await icp_request
        .post("public/requests/set_requested_credit", request, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            // console.log("Le document ==> : "+document.get("documentId"));
          }
        });
    },
    async validateRequest(_, document) {
      await icp_request
        .post("public/requests/validate", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Validation réussie !",
              text: "La demande de financement a été validée avec succès",
            });
            router.push({ name: "welcomeRequest" });
            // console.log("Le document ==> : "+document.get("documentId"));
          }
        });
    },
    async rejectRequest(_, document) {
      await icp_request
        .post("public/requests/reject", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Rejet réussi !",
              text: "La demande de financement a été rejetée avec succès",
            });
            router.push({ name: "welcomeRequest" });
            // console.log("Le document ==> : "+document.get("documentId"));
          }
        });
    },
    async approveRequest(_, document) {
      await icp_request
        .post("public/requests/approve", document, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Approbation réussie !",
              text: "La demande de financement a été approuvée avec succès",
            });
            router.push({ name: "welcomeRequest" });
          }
        });
    },
    async sendCreditNotification(_, notification) {
      await icp_request
        .post("public/commitments/seedDocs", notification, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log("response : "+response)
          // console.log("response data "+response.data)
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Transmission réussie !",
              text: "La notification de crédit a été envoyée à la PME/Consultant avec succès",
            });
            router.push({ name: "welcomeRequest" });
          }
        });
    },
    async downloadCreditFile(_, id) {
      await axios
        .get(`${this.url}downloads/` + id, {
          headers: { "Content-Type": "application/pdf" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Téléchargement réussi !",
              text: "La notification de crédit a été téléchargée avec succès",
            });
            // console.log("Le document ==> : "+document.get("documentId"));
          }
        });
    },
    async getBucketDocs({ commit }, idBucket) {
      commit("SET_LOADING_DOCS", true);
      await icp_request
        .get("public/buckets/getDocs/" + idBucket, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            // state.documentsList = response.data.documents;
            // console.log(response.data.documents);
            commit("SET_LOADING_DOCS", false);
            commit("SET_DOCUMENTS_LIST", response.data.documents);
          }
        });
    },
    async getRequestById({ commit }, id) {
      await icp_request
        .get("public/requests/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            // console.log(id);
            commit("SET_SELECTED_REQUEST", response.data.request);
            commit("SET_S_REQUEST_BUCKETS", response.data.buckets);
            commit("SET_S_REQUEST_COMMITMENTS", response.data.commitments);
            commit("SET_S_REQUEST_TIMELINE", response.data.timelines);
          }
        });
    },
  },
};
