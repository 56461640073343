import { icp_request } from "../../config/axios";
import router from "../../router";
import Swal from "sweetalert2";
const url = process.env.VUE_APP_SPF_BASE_URL;
// import.meta.env.VITE_SPF_BASE_URL;
export default {
  namespaced: true,
  state: {
    token: "",
    menuSidebar: true,
    timelineSideMenu: true,
    user: JSON.parse(localStorage.getItem("userData")),
    active: false,
    userPassReset:null
  },
  getters: {
    get_user_pass_reset(state){
      return state.userPassReset;
    },
    isActive(state) {
      return state.active;
    },
    get_menu_sidebar(state) {
      return state.menuSidebar;
    },
    get_timeline_side_menu(state) {
      return state.timelineSideMenu;
    },
  },
  mutations: {
    SET_ACTIVE: (state, value) => (state.active = value),
    SET_TOKEN(state, value) {
      state.token = value;
      if (state.token) {
        state.active = true;
      } else {
        state.active = false;
      }
    },
    SET_SWITCH_MENU_SIDEBAR(state, value) {
      switch (value) {
        case 1:
          state.menuSidebar = state.menuSidebar = true;
          break;
        case 2:
          state.menuSidebar = state.menuSidebar = false;
          break;
      }
    },
    SWITCH_MENU_SIDEBAR(state) {
      state.menuSidebar = !state.menuSidebar;
    },
    SET_USER: (state, value) =>
      localStorage.setItem("userData", JSON.stringify(value)),
    LOG_OUT(state) {
      state.token = null;
      delete icp_request.defaults.headers.common["Authorization"];
    },
    SET_USER_PASS_RESET(state,value){
      state.userPassReset = value;
    }
  },
  actions: {
    async setMenuSidebar({ commit }, state) {
      commit("SET_SWITCH_MENU_SIDEBAR", state);
    },
    async switchMenuSidebar({ commit }) {
      commit("SWITCH_MENU_SIDEBAR");
    },
    async logOut({ commit }) {
      commit("LOG_OUT");
      commit("SET_USER", null);
      commit("SET_TOKEN", null);
      await localStorage.clear();
      router.push({ name: "login" });
    },
    async switchUserPassReset({ commit }) {
      commit("SET_USER_PASS_RESET",null);
    },
    async logInAttempt({ commit }, token) {
      if (token) {
        localStorage.setItem("icp_pass", token);
        icp_request.defaults.headers.common.Authorization = "Bearer " + token;
        return await commit("SET_TOKEN", token);
      } else {
        delete icp_request.defaults.headers.common["Authorization"];
      }
    },
    async logIn({ dispatch, commit }, credentials) {
      await icp_request
        .post("login", credentials, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            if(!response.data.user.onBoarding){
              dispatch("Auth/logInAttempt", response.data.access_token,{ root: true });
              commit("SET_USER", response.data.user);
              router.push({ name: "dashboard" });
            }else{
              commit("SET_USER_PASS_RESET", {... response.data.user,'token': response.data.access_token});
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Attention !",
              text: "Une erreur s'est produite - Veuillez réessayez svp",
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: error.response.data.message,
          });
          // console.log(error.response.data.message);
        });
    },
    async recoverCompanyUi(_, data) {
      await icp_request
        .post("public/companies/recover_company_ui", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            // console.log(response.data);
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La compagnie a été ajoutée avec succès",
            });
            router.push({ name: "recovery" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Revoir les champs saisies!",
            });
          }
        });
    },
    async recoverUserUi(_, data) {
      await icp_request
        .post("private/users/recover_user_ui", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            // console.log(response.data);
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "L'utilisateur a été ajoutée avec succès",
            });
            router.push({ name: "recovery" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Revoir les champs saisies!",
            });
          }
        });
    },
    async recoverRequestUi(_, data) {
      await icp_request
        .post("public/requests/recover_request_ui", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            // console.log(response.data);
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La demande a été ajoutée avec succès",
            });
            router.push({ name: "recovery" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Revoir les champs saisies!",
            });
          }
        });
    },
  },
};
