import axios from "axios";
import { store } from "../store/index";
import Swal from "sweetalert2";

// CONFIGURATIONS DU BASE URL
console.log(process.env)
// console.log(import.meta)
const baseURL = {baseURL: process.env.VUE_APP_SPF_BASE_URL};
export const icp_request = axios.create(baseURL);


// CONFIGURATIONS DES INTERCEPTEURS

icp_request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // SI LA RESSOURCE N'EST PAS TROUVEE
    if (403 === error.response.status) {
      Swal.fire({
        icon: "error",
        title: "Oops !",
        text: "Votre session a expiré - Veuillez vous réauthentifier svp",
      }).then(function () {
        store.dispatch("Auth/logOut");
      });
    } else {
      return Promise.reject(error);
    }
  }
);

// TOKEN D'ACCESS JWT BEARER

icp_request.defaults.headers.common.Authorization =
  `Bearer ` + localStorage.getItem("icp_pass");
