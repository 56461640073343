import { icp_request } from "../../config/axios";
import router from "../../router";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    commitmentsList: [],
    commitmentsDocs: [],
    selectedCommitment: [],
    selectedFunding: [],
  },
  getters: {
    get_commitments_list(state) {
      return state.commitmentsList;
    },
    get_commitments_docs(state) {
      return state.commitmentsDocs;
    },
    get_selected_commitment(state) {
      return state.selectedCommitment;
    },
    get_selected_funding(state) {
      return state.selectedFunding;
    },
  },
  mutations: {
    SET_COMMITMENTS_LIST: (state, value) => (state.commitmentsList = value),
    SET_COMMITMENTS_DOCS: (state, value) => (state.commitmentsDocs = value),
    SET_SELECTED_COMMITMENT: (state, value) =>
      (state.selectedCommitment = value),
    SET_SELECTED_FUNDING: (state, value) => (state.selectedFunding = value),
  },
  actions: {
    async getAllCommitments({ commit }) {
      await icp_request
        .get("public/commitments/all", {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_COMMITMENTS_LIST", response.data.commitments);
          }
        });
    },
    async getCommitmentById({ commit }, commitmentId) {
      await icp_request
        .get("public/commitments/get/" + commitmentId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_SELECTED_COMMITMENT", response.data.commitment);
            commit("SET_SELECTED_FUNDING", response.data.funding);
          }
        });
    },
    async getCommitmentsDocs({ commit }, commitmentId) {
      await icp_request
        .get("public/commitments/getDocs/" + commitmentId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_COMMITMENTS_DOCS", response.data);
          }
        });
    },
    async rejectCreditPME(_, commitment) {
      await icp_request
        .post("public/commitments/rejectCreditByPme", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Refus réussi !",
              text: "L'offre de crédit de la banque a été refusée par la PME/Consultant avec succès",
            });
            router.push({ name: "notifications" });
          }
        });
    },
    async acceptCreditPME(_, commitment) {
      await icp_request
        .post("public/commitments/acceptCreditByPme", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Soumission réussie !",
              text: "Les conditions de mise en place de crédit ont été soumises avec succès à l'ADEPME",
            });
            router.push({ name: "notifications" });
          } else {
            Swal.fire({
              icon: "error",
              text: "Veuillez joindre au moins une condition de mise en place",
            });
          }
        });
    },
    async retransferCreditPME(_, commitment) {
      await icp_request
        .post("public/commitments/retransferCreditPme", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Renvoi réussi !",
              text: "Les conditions de mise en place de crédit ont été renvoyées avec succès à la PME/Consultant",
            });
            router.push({ name: "notifications" });
          }
        });
    },
    async transferCreditToBank(_, commitment) {
      await icp_request
        .post("public/commitments/transferCreditToBank", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Transmission réussie !",
              text: "Les conditions de mise en place de crédit de la PME/Consultant ont été renvoyées avec succès à la banque",
            });
            router.push({ name: "notifications" });
          }
        });
    },
    async bankAcceptCredit(_, commitment) {
      await icp_request
        .post("public/commitments/bankAcceptCredit", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Accord réussi !",
              text: "Le crédit de financement à été accordé avec succès",
            });
            router.push({ name: "notifications" });
          }
        });
    },
    async bankRefuseCredit(_, commitment) {
      await icp_request
        .post("public/commitments/bankRefuseCredit", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Refus réussi !",
              text: "Le crédit de financement à été refusé avec succès",
            });
            router.push({ name: "notifications" });
          }
        });
    },
    async shareFunding(_, commitment) {
      await icp_request
        .post("public/commitments/share/fundings", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Tranmission réussie !',
            //     text: "Le contrat de crédit à été envoyé à la PME/Consultant avec succès",
            // })
            router.push({ name: "notifications" });
          }
        });
    },
    async payPart(_, commitment) {
      await icp_request
        .post("public/commitments/paypart", commitment, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Tranmission réussie !",
              text: "Le contrat de crédit à été envoyé à la PME/Consultant avec succès",
            });
            // router.push({name: "notifications"});
          }
        });
    },
    async addCondition(_, commitment) {
      await icp_request
        .post("public/commitments/add_new/doc", commitment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La condition de mise en place a été rajoutée avec succès",
            });
            // router.push({name: "notifications"});
          }
        });
    },
    async getAllFundings({ commit }) {
      await icp_request.get("public/fundings/all").then((response) => {
        if (response.data) {
          commit("SET_S_COMMITMENT", response.data);
        }
      });
    },
    async getFundingById({ commit }, fundingId) {
      await icp_request
        .get("public/fundings/" + fundingId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_SELECTED_COMMITMENT", response.data);
          }
        });
    },
  },
};
