import { icp_request } from "../../config/axios";
import router from "../../router";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    allBank: [],
    selectedBank: [],
    setAllBankUser: [],
  },
  getters: {
    get_bank_list(state) {
      return state.allBank;
    },
    get_selected_bank(state) {
      return state.selectedBank;
    },
    get_all_bank_user_list(state) {
      return state.setAllBankUser;
    },
  },
  mutations: {
    SET_ALL_BANK: (state, value) => (state.allBank = value),
    SET_SELECTED_BANK: (state, value) => (state.selectedBank = value),

    SET_ALL_BANK_USER: (state, value) => (state.setAllBankUser = value),
  },
  actions: {
    async getAllBankUser({ commit }) {
      await icp_request.get("/private/users/all").then((response) => {
        if (response.data) {
          commit("SET_ALL_BANK_USER", response.data);
        }
      });
    },
    async authoriseBankOnBucket(_, obj) {
      await icp_request
        .post("public/requests/authorise", obj, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Transmission réussie !",
              text: "La demande de financement a été transmise avec succès à la banque",
            });
            router.push({ name: "welcomeRequest" });
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Attention !",
            text: "La transmission de la demande nécessite au moins un document",
          });
        });
    },
    async getBankById({ commit }, id) {
      await icp_request
        .get("public/register/bank/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_SELECTED_BANK", response.data);
          }
        });
    },
    async setBankById({ commit }, id) {
      await icp_request
        .set("public/register/bank/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_SELECTED_BANK", response.data);
          }
        });
    },
  },
};
