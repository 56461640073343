import { icp_request } from "../../config/axios";
import router from "../../router";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    loading:false,
    fundingsList: [],
    selectedFunding: [],
    reportsList: [],
    financialIndicators: [],
    financialIndicatorsDatas: [],
    oneBudgetData: [],
    consolidated: [],
    followUpActions: [],
    revenues: [],
    budgets: [],
  },
  getters: {
    get_fundings_list(state) {
      return state.fundingsList;
    },
    get_is_uploading_progress(state){
      return state.loading;
    },
    get_selected_funding(state) {
      return state.selectedFunding;
    },
    get_reports_list(state) {
      return state.reportsList;
    },
    get_financial_indicators(state) {
      return state.financialIndicators;
    },
    get_financial_indicators_datas(state) {
      return state.financialIndicatorsDatas;
    },
    get_one_budget_data(state) {
      return state.oneBudgetData;
    },
    get_consolidated(state) {
      return state.consolidated;
    },
    get_follow_up_actions(state) {
      return state.followUpActions;
    },
    get_revenues_data(state) {
      return state.revenues;
    },
    get_budgets_data(state) {
      return state.budgets;
    },
  },
  mutations: {
    SET_FUNDINGS_LIST: (state, value) => (state.fundingsList = value),
    set_is_uploading_progress(state, value){
      state.loading = value;
    },
    SET_SELECTED_FUNDING: (state, value) => (state.selectedFunding = value),
    SET_REPORTS_LIST: (state, value) => (state.reportsList = value),
    SET_FINANCIAL_INDICATORS: (state, value) =>
      (state.financialIndicators = value),
    SET_FINANCIAL_INDICATORS_DATAS: (state, value) =>
      (state.financialIndicatorsDatas = value),
    SET_ONE_BUDGET_DATA: (state, value) => (state.oneBudgetData = value),
    SET_CONSOLIDATED: (state, value) => (state.consolidated = value),
    SET_FOLLOW_UP_LIST: (state, value) => (state.followUpActions = value),
    SET_REVENUES: (state, value) => (state.revenues = value),
    SET_BUDGETS: (state, value) => (state.budgets = value),
  },
  actions: {
    async getAllFundings({ commit }) {
      await icp_request.get("public/fundings/all").then((response) => {
        if (response.data) {
          commit(
            "SET_FUNDINGS_LIST",
            response.data.fundings.sort((a, b) => {
              if (a.loanAmount > b.loanAmount) {
                return -1;
              }
              if (a.loanAmount < b.loanAmount) {
                return 1;
              }

              return 0;
            })
          );
        }
      });
    },
    async getFundingById({ commit }, fundingId) {
      await icp_request
        .get("public/fundings/get/" + fundingId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_SELECTED_FUNDING", response.data);
          }
        });
    },
    async getAllReports({ commit }, fundingId) {
      await icp_request
        .get("public/fundings/getListReports/" + fundingId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_REPORTS_LIST", response.data);
          }
        });
    },
    async getReportsInfo(_, fundingId) {
      await icp_request
        .get("public/fundings/getReportsInfo/" + fundingId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            // commit("SET_REPORTS_LIST", response.data);
            console.log("getReportsInfo", response.data);
          }
        });
    },
    async getFinancialIndicators({ commit }, fundingId) {
      await icp_request
        .get("public/fundings/getListFinancialIndicator/" + fundingId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_FINANCIAL_INDICATORS", response.data);
          }
        });
    },
    async setBudgetDirectives({ dispatch }, report) {
      await icp_request
        .post("public/fundings/setBudgetDirective", report, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getBudgets", response.data.funding.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "L'observation a été ajoutée avec succès",
            });
          }
        });
    },
    async setBudgetByMonth({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setBudgetByMonth", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getBudgets", response.data.funding.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "Le chiffre d'affaire confié du mois a été mis à jour avec succès",
            });
          }
        });
    },
    async setBudgetByWeek({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setBudgetByWeek", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getBudgets", response.data.funding.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "Le chiffre d'affaire confié de la semaine a été mis à jour avec succès",
            });
          }
        });
    },
    async setOrigin({ dispatch }, report) {
      await icp_request
        .post("public/fundings/updateOrigin", report, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getAllReports", response.data.funding.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "L'origine a été ajoutée avec succès",
            });
          }
        });
    },
    async setDirectives({ dispatch }, report) {
      await icp_request
        .post("public/fundings/updateDirectives", report, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getAllReports", response.data.funding.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "L'action corrective a été ajoutée avec succès",
            });
          }
        });
    },
    async setReportDelayed({ dispatch }, report) {
      await icp_request
        .post("public/fundings/setReportDelayed", report, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getAllReports", response.data.funding.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Mise à jour effectuée !",
              text: "Le retard de paiement est activé. Le nombre de jours de retard paiement sera automatiquement calculé.",
            });
          }
        });
    },
    async validateOrRejectPayment({ dispatch }, report) {
      await icp_request
        .post("public/fundings/validateOrRejectPayment", report, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
          dispatch("Fundings/getAllReports", response.data.funding.id,{ root: true });
        });
    },
    async setFinancialIndicatorText({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setFinancialIndicatorText", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // dispatch('getConsolitadedFinancialIndicatorData',response.data.financialIndicator.funding.id);
          if (response) {
            data.append("id", response.data.financialIndicator.funding.id);
            data.append("year", response.data.financialIndicator.year);

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });
            dispatch(
              "Fundings/getFundingById",
              response.data.financialIndicator.funding.id,{ root: true }
            );
            dispatch(
              "Fundings/getFinancialIndicators",
              response.data.financialIndicator.funding.id,{ root: true }
            );
            dispatch(
              "Fundings/getFinancialIndicatorsDatas",
              response.data.financialIndicator.id,{ root: true }
            );
            dispatch("Fundings/getConsolitadedFinancialIndicatorData", data,{ root: true });
          }
        });
    },
    async setFinancialIndicatorFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setFinancialIndicatorFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });

            dispatch(
              "Fundings/getFundingById",
              response.data.financialIndicator.funding.id,{ root: true }
            );
            dispatch(
              "Fundings/getFinancialIndicators",
              response.data.financialIndicator.funding.id,{ root: true }
            );
            dispatch(
              "Fundings/getFinancialIndicatorsDatas",
              response.data.financialIndicator.id,{ root: true }
            );
          }
        });
    },
    async setRevenueFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setRevenueFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            let data = new FormData();
            data.append("id", response.data.funding.id);
            switch (response.data.revenueTypes) {
              case "LANDS":
                data.append("type", 1);
                break;
              case "FACILITIES":
                data.append("type", 2);
                break;
              case "MATERIALS":
                data.append("type", 3);
                break;
              case "TRANSPORT":
                data.append("type", 4);
                break;
              case "FINANCIAL_ASSETS":
                data.append("type", 5);
                break;
              case "INTANGIBLE":
                data.append("type", 6);
                break;
              case "FUNDS_NEEDS":
                data.append("type", 7);
                break;
            }
            data.append("type", this.selectedType);

            dispatch("Fundigs/getRevenues", data,{ root: true });
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });

            dispatch("Fundings/getFundingById", response.data.funding.id,{ root: true });
            dispatch("Fundings/getRevenues", response.data.funding.id,{ root: true });
          }
        });
    },
    async removeFinancialIndicatorFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/removeFinancialIndicatorFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussi !",
              text: "La suppression a été effectuée avec succès",
            });
            dispatch(
              "Fundings/getFundingById",
              response.data.financialIndicator.funding.id,{ root: true }
            );
            dispatch(
              "Fundings/getFinancialIndicators",
              response.data.financialIndicator.funding.id,{ root: true }
            );
            dispatch(
              "Fundings/getFinancialIndicatorsDatas",
              response.data.financialIndicator.id,{ root: true }
            );
          }
        });
    },
    async removeFollowUpFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/removeFollowUpFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getFollowUpActionsByFunding", response.data.funding.id,{ root: true });
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussi !",
              text: "La suppression a été effectuée avec succès",
            });
          }
        });
    },
    async removeBudgetFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/removeBudgetFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getBudgets", response.data.funding.id,{ root: true });
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussi !",
              text: "La suppression a été effectuée avec succès",
            });
          }
        });
    },
    async removeRevenueFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/removeRevenueFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          let data = new FormData();
          data.append("id", response.data.funding.id);
          switch (response.data.revenueTypes) {
            case "LANDS":
              data.append("type", 1);
              break;
            case "FACILITIES":
              data.append("type", 2);
              break;
            case "MATERIALS":
              data.append("type", 3);
              break;
            case "TRANSPORT":
              data.append("type", 4);
              break;
            case "FINANCIAL_ASSETS":
              data.append("type", 5);
              break;
            case "INTANGIBLE":
              data.append("type", 6);
              break;
            case "FUNDS_NEEDS":
              data.append("type", 7);
              break;
          }
          data.append("type", this.selectedType);

          dispatch("Fundings/getRevenues", data,{ root: true });

          if (response) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussi !",
              text: "La suppression a été effectuée avec succès",
            });
          }
        });
    },
    async setFollowUpFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setFollowUpFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getFollowUpActionsByFunding", response.data.funding.id,{ root: true });
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });
          }
        });
    },
    async setBudgetFile({ dispatch }, data) {
      await icp_request
        .post("public/fundings/setBudgetFile", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getBudgets", response.data.funding.id,{ root: true });
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });
          }
        });
    },
    async updateRevenue({ dispatch }, data) {
      await icp_request
        .post("public/fundings/updateRevenue", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          let data = new FormData();
          data.append("id", response.data.funding.id);
          switch (response.data.revenueTypes) {
            case "LANDS":
              data.append("type", 1);
              break;
            case "FACILITIES":
              data.append("type", 2);
              break;
            case "MATERIALS":
              data.append("type", 3);
              break;
            case "TRANSPORT":
              data.append("type", 4);
              break;
            case "FINANCIAL_ASSETS":
              data.append("type", 5);
              break;
            case "INTANGIBLE":
              data.append("type", 6);
              break;
            case "FUNDS_NEEDS":
              data.append("type", 7);
              break;
          }
          data.append("type", this.selectedType);

          dispatch("Fundings/getRevenues", data,{ root: true });

          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });
          }
        });
    },
    async updateFollowUpActions({ dispatch }, data) {
      await icp_request
        .post("public/fundings/updateFollowUpActions", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getFollowUpActionsByFunding", response.data.funding.id,{ root: true });
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La mise à jour a été effectuée avec succès",
            });
          }
        });
    },
    async addFollowUpActions({ dispatch }, data) {
      await icp_request
        .post("public/fundings/addFollowUpActions", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch("Fundings/getFollowUpActionsByFunding", response.data.funding.id,{ root: true });
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "L'action de suivi a été ajoutée avec succès",
            });
          }
        });
    },
    async addRevenue({ dispatch }, data) {
      await icp_request
        .post("public/fundings/addRevenue", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            let data = new FormData();
            data.append("id", response.data.funding.id);
            switch (response.data.revenueTypes) {
              case "LANDS":
                data.append("type", 1);
                break;
              case "FACILITIES":
                data.append("type", 2);
                break;
              case "MATERIALS":
                data.append("type", 3);
                break;
              case "TRANSPORT":
                data.append("type", 4);
                break;
              case "FINANCIAL_ASSETS":
                data.append("type", 5);
                break;
              case "INTANGIBLE":
                data.append("type", 6);
                break;
              case "FUNDS_NEEDS":
                data.append("type", 7);
                break;
            }
            data.append("type", this.selectedType);

            dispatch("Fundings/getRevenues", data,{ root: true });
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "La rubrique a été ajoutée avec succès",
            });
          }
        });
    },
    async addBudget(_, data) {
      await icp_request
        .post("public/fundings/addBudget", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "Le tableau de chiffres d'affaire confiés a été généré avec succès",
            });
            // WEEKLY("Hebdomadaire", 1),
            // MONTHLY("Mensuelle", 2),
            // BIMONTHLY("Bimestriel", 3),
            // QUARTELY("Trimestrielle", 4),
            // BIANNUAL("Semestrielle", 5),
            // YEARLY("Annuelle", 6);
            switch (response.data.budgetTypes) {
              case "WEEKLY":
                router.push({
                  name: "turnover",
                  params: {
                    fundingId: Number.parseInt(response.data.id),
                    periodicity: 1,
                  },
                });
                break;
              case "MONTHLY":
                router.push({
                  name: "turnover",
                  params: {
                    fundingId: Number.parseInt(response.data.id),
                    periodicity: 2,
                  },
                });
                break;
              case "BIMONTHLY":
                router.push({
                  name: "turnover",
                  params: {
                    fundingId: Number.parseInt(response.data.id),
                    periodicity: 3,
                  },
                });
                break;
              case "QUARTELY":
                router.push({
                  name: "turnover",
                  params: {
                    fundingId: Number.parseInt(response.data.id),
                    periodicity: 4,
                  },
                });
                break;
              case "BIANNUAL":
                router.push({
                  name: "turnover",
                  params: {
                    fundingId: Number.parseInt(response.data.id),
                    periodicity: 5,
                  },
                });
                break;
              case "YEARLY":
                router.push({
                  name: "turnover",
                  params: {
                    fundingId: Number.parseInt(response.data.id),
                    periodicity: 6,
                  },
                });
                break;
              default:
                break;
            }
          }
        });
    },
    async getRevenues({ commit }, financial) {
      await icp_request
        .get(
          "public/fundings/getRevenues/" +
            financial.get("id") +
            "/" +
            financial.get("type"),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data) {
            commit("SET_REVENUES", response.data);
          }
        });
    },
    async getBudgets({ commit }, fundId) {
      await icp_request
        .get("public/fundings/getBudgets/" + fundId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_BUDGETS", response.data);
          }
        });
    },
    async setPayment({ dispatch }, payment) {
      await icp_request
        .post("public/fundings/set_payment", payment, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response) {
            dispatch("Fundings/getFundingById", response.data.id,{ root: true });
            dispatch("Fundings/getAllReports", response.data.id,{ root: true });

            Swal.fire({
              icon: "success",
              title: "Ajout réussi !",
              text: "Le paiement a été effectué avec succès",
            });
          }
        });
    },
    async getFinancialIndicatorsDatas({ commit }, trimesterId) {
      await icp_request
        .get("public/fundings/getOneFinancialIndicatorData/" + trimesterId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_FINANCIAL_INDICATORS_DATAS", response.data);
          }
        });
    },
    async getOneBudgetData({ commit }, bId) {
      await icp_request
        .get("public/fundings/getOneBudgetData/" + bId, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_ONE_BUDGET_DATA", response.data);
          }
        });
    },
    async getFollowUpActionsByFunding({ commit }, fund) {
      await icp_request
        .get("public/fundings/getFollowUpActionsByFunding/" + fund, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_FOLLOW_UP_LIST", response.data);
          }
        });
    },
    async getConsolitadedFinancialIndicatorData({ commit }, financial) {
      await icp_request
        .get(
          "public/fundings/getConsolitadedFinancialIndicatorData/" +
            financial.get("id") +
            "/" +
            financial.get("year"),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data) {
            commit("SET_CONSOLIDATED", response.data);
          }
        });
    },
    async processImportBudget({ dispatch }, budgets) {
      await icp_request
        .post("public/fundings/processImportBudget", budgets, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          dispatch("Fundings/getFundingById", response.data[0].funding,{ root: true });
          dispatch("Fundings/getBudgets", response.data[0].funding,{ root: true });

          if (response) {
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
          }
        });
    },
    async processImportRefund({ dispatch }, refunds) {
      await icp_request
        .post("public/fundings/processImportRefund", refunds, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          dispatch("Fundings/getFundingById", response.data[0].funding,{ root: true });
          dispatch("Fundings/getAllReports", response.data[0].funding,{ root: true });

          if (response) {
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
          }
        });
    },
    async processImportRevenue({ dispatch }, refunds) {
      await icp_request
        .post("public/fundings/processImportRevenue", refunds, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          dispatch("Fundings/getFundingById", response.data[0].funding,{ root: true });
          dispatch("Fundings/getRevenues", response.data[0].funding.id,{ root: true });

          if (response) {
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
          }
        });
    },
    async processImportFollow({ dispatch }, follows) {
      await icp_request
        .post("public/fundings/processImportFollow", follows, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          dispatch("Fundings/getFundingById", response.data[0].funding,{ root: true });
          dispatch("Fundings/getFollowUpActionsByFunding", response.data[0].funding,{ root: true });

          if (response) {
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les actions de suivi ont été importées avec succès.",
            });
          }
        });
    },
    async processImportFinancials({ dispatch }, financial) {
      await icp_request
        .post("public/fundings/processImportFinancial", financial, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          dispatch("Fundings/getFundingById", response.data[0].funding,{ root: true });
          dispatch("Fundings/getFinancialIndicators", response.data[0].funding,{ root: true });
          console.log(response);
          if (response.data[0]) {
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Importation échouee !",
              text: "Les données n'ont pas été mises à jour.",
            });
          }
        });
    },
    async processImportPme({commit,dispatch}, pme) {
      commit('set_is_uploading_progress',true);
      await icp_request
        .post("public/companies/processImportPme", pme, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
        
          console.log(response);
          if (response.data[0]) {
            dispatch("Requests/getAllUsers", {}, {root:true});
            dispatch("Requests/getAllRequests", {}, {root:true});
            dispatch("Requests/getAllCompanies", {}, {root:true});
  
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Importation échouee !",
              text: "Les données n'ont pas été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          };
        })
        .catch((e)=>{
          console.log(e);
          commit('set_is_uploading_progress',false);
        });
    },
    async processImportBank({commit,dispatch}, bank) {
      commit('set_is_uploading_progress',true);
      await icp_request
        .post("public/companies/processImportBank", bank, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(response);
          if (response.data[0]) {
            dispatch("Requests/getAllUsers", {}, {root:true});
            dispatch("Requests/getAllRequests", {}, {root:true});
            dispatch("Requests/getAllCompanies", {}, {root:true});
  
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Importation échouee !",
              text: "Les données n'ont pas été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          }
        })
        .catch((e)=>{
          console.log(e);
          commit('set_is_uploading_progress',false);
        });
    },
    async processImportRequest({commit,dispatch}, bank) {
      commit('set_is_uploading_progress',true);
      await icp_request
        .post("public/companies/processImportRequest", bank, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {

          console.log(response);
          if (response.data[0]) {
            dispatch("Requests/getAllUsers", {}, {root:true});
            dispatch("Requests/getAllRequests", {}, {root:true});
            dispatch("Requests/getAllCompanies", {}, {root:true});
  
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });

            commit('set_is_uploading_progress',false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Importation échouee !",
              text: "Les données n'ont pas été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          }
        })
        .catch((e)=>{
          console.log(e);
          commit('set_is_uploading_progress',false);
        });
    },
    async processImportAdepme({commit,dispatch}, user) {
      commit('set_is_uploading_progress',true);
      await icp_request
        .post("public/companies/processImportAdepme", user, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(response);
          if (response.data[0]) {
            dispatch("Requests/getAllUsers", {}, {root:true});
            dispatch("Requests/getAllRequests", {}, {root:true});
            dispatch("Requests/getAllCompanies", {}, {root:true});
  
            Swal.fire({
              icon: "success",
              title: "Importation réussie !",
              text: "Les données ont été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Importation échouee !",
              text: "Les données n'ont pas été mises à jour.",
            });
            commit('set_is_uploading_progress',false);
          }
        })        
        .catch((e)=>{
          console.log(e);
          commit('set_is_uploading_progress',false);
        });
    },
  },
};
