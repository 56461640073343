import { icp_request } from "../../config/axios";

export default {
  namespaced: true,
  state: {
    allProjects: [],
    selectedProject: [],
  },
  getters: {
    get_projects_list(state) {
      return state.allProjects;
    },
    get_selected_projects(state) {
      return state.selectedProject;
    },
  },
  mutations: {
    SET_ALL_PROJECTS: (state, value) => (state.allProjects = value),
    SET_SELECTED_PROJECT: (state, value) => (state.selectedProject = value),
  },
  actions: {
    async getAllProjects({ commit }) {
      await icp_request.get("public/projects/all").then((response) => {
        if (response) {
          commit("SET_ALL_PROJECTS", response.data);
        }
      });
    },
    async getProjectById({ commit }, id) {
      await icp_request
        .get("public/Projects/get/" + id, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data) {
            commit("SET_SELECTED_PROJECT", response.data);
          }
        });
    },
  },
};
