import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
// import VueApexCharts from 'vue-apexcharts'
import VTooltip from "v-tooltip";
import DatePicker from "vue2-datepicker";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "./assets/main.css";
import moment from "moment";
import { prototype } from "postcss/lib/comment";
// import JsonExcel from "vue-json-excel";

Vue.prototype.moment = moment;
Vue.config.productionTip = true;
Vue.config.debug = false;
Vue.config.silent = true;

const options = {
  position: "fixed",
  show: true,
  height: "8px",
};

Vue.use(VueInsProgressBar, options);
Vue.use(DatePicker);
Vue.use(VueToast);
Vue.use(VTooltip);
// Vue.use(VueApexCharts);
// Vue.component('apexchart', VueApexCharts)
// Vue.component("downloadExcel", JsonExcel);

// Reauth
store.dispatch("Auth/logInAttempt", localStorage.getItem("icp_pass"));
// store.dispatch('Auth/getData');

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
